<template>
    <div class="zl-list">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>企微群发</a-breadcrumb-item>
            <a-breadcrumb-item>编辑群发</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box sendmessage">
            <div class="title flts-title">
                <h3>规则基本信息</h3>
                <div>
                    <a-button size="small" style="margin-left:10px" @click="handleSave">保存</a-button>
                    <a-button size="small" style="margin-left:10px" @click="handleCancel">返回</a-button>
                </div>
            </div>
            <a-row>
                <a-col :span="16">
                    <a-form layout="vertical">
                        <a-form-item label="SOP分组">
                            <a-select placeholder="请选择" showSearch :filterOption="filterOption" v-model="group_id">
                                <a-select-option v-for="(d, index) of group_list" :key="index" :value="d.group_id">{{ d.group_name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="sop名称">
                            <a-input v-model="name" placeholder="请输入sop名称" />
                        </a-form-item>
                        <a-form-item label="适用成员">
                            <a-popover v-model="isCheckWarp" title="选择" trigger="click">
                                <div slot="content">
                                    <checkWarp />
                                </div>
                                <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
                                    <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
                                    <a-icon style='fontSize:18px' type="plus-circle" />
                                </div>
                            </a-popover>
                        </a-form-item>
                    </a-form>
                </a-col>
            </a-row>
            <div class="title flts-title">
                <h3>设置推送周期及内容</h3>
            </div>
            <div>
                <a-form :form="form" layout="vertical">
                    <a-form-item v-for="(item, index) of formData" :key="`${index}s`"  :label="item.label">
                        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                        <a-radio-group @change="handleRadios" v-else-if="item.type === 'radios'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                            <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
                        </a-radio-group>
                        <div v-else-if="item.type === 'self'">
                            <div v-if="radiosIndex == 'expiration_reminder'">
                                <span>少于多少课时</span>
                                <a-input-number style="margin: 0 10px" :min="1" :value="expiration_reminder_less_than"/>
                                <span>课时</span>
                            </div>
                            <div v-if="radiosIndex == 'schedule_reminder'">
                                <div v-for="(it,index) in week_schedule" :key='index' class='addSelf'>
                                    <a-form-item label="" >
                                    <a-checkbox-group
                                        v-model="it.week"
                                        name="checkboxgroup"
                                        :options="plainOptions"
                                    />
                                    </a-form-item>
                                    <a-form-item label="提醒时间" >
                                    <span style='margin-right:5px'>
                                        <a-time-picker v-model="it.week_time" :allowClear="false" format="HH:mm">
                                        </a-time-picker>
                                    </span>
                                    </a-form-item>
                                </div>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item v-for="(item, index) of sop_contents" :key="index" label="内容">
                        <div @click="sop_contents_index = index" style="margin-bottom:10px;">
                            <a-radio-group :value="item.radioVal" @change="e=>handleChange(e,index)">
                                <a-radio value="text">文字</a-radio>
                                <a-radio value="image">图片</a-radio>
                                <a-radio value="video">视频</a-radio>
                                <a-radio value="file">文件</a-radio>
                            </a-radio-group>
                            <a-icon v-if="sop_contents.length>1" @click="delSopContents(index)" style="float: right;cursor: pointer;" type="delete" />
                        </div>
                        <div @click="sop_contents_index = index" v-decorator="['sop_contents', { rules: [{ required: true, message: '请输入话术内容!' }] }]">
                            <div v-if="item.radioVal == 'text'">
                                <a-textarea @change="handleText" v-model="item.content" placeholder="请输入话术内容" :rows="4" />
                            </div>
                            <div class="talkingSkills-videoBox" v-if="item.radioVal == 'image'">
                                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                                <div style="margin-top:10px">
                                    <a-upload
                                        accept='.png,.jpeg,.jpg'
                                        list-type="picture-card"
                                        :file-list="item.imgList"
                                        :data="uploadParams"
                                        :action="uploadUrl"
                                        :multiple='false'
                                        :beforeUpload="beforeUploadImg"
                                        @change="changeImg">
                                        <div>
                                            <a-icon type="plus" />
                                        </div>
                                    </a-upload>
                                </div>
                            </div>
                            <div class="talkingSkills-videoBox" v-if="item.radioVal == 'video'">
                                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                                <div class="talkingSkills-videoImg" @click="toVideo" v-if="item.videoImg">
                                    <a-icon class="talkingSkills-videoImg-play" type="play-circle" theme="twoTone" />
                                    <a-icon @click.stop="delVideo" class="talkingSkills-videoImg-close" type="close-circle" theme="twoTone" two-tone-color="#999" />
                                    <img :src="item.videoImg">
                                </div>
                                <div>
                                    <a-upload
                                        accept='.avi,.rmvb,.rm,.asf,.divx,.mpg,.mpeg,.mpe,.wmv,.mp4,.mkv,.vob'
                                        :data="uploadParams"
                                        :action="uploadUrl"
                                        :multiple='false'
                                        :show-upload-list="false"
                                        :beforeUpload="beforeUploadVideo"
                                        @change="changeVideo">
                                        <div style="margin-top:10px" v-if="item.videoImg">
                                            <a-button> <a-icon type="upload" />重新上传</a-button>
                                        </div>
                                        <div v-else class="talkingSkills-video">
                                            <a-icon :style="{fontSize:'32px',color:'#999'}" type="plus" />
                                        </div>
                                    </a-upload>
                                </div>
                            </div>
                            <div class="talkingSkills-videoBox" v-if="item.radioVal == 'file'">
                                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                                <div v-if="item.fileInfo" class="talkingSkills-file">
                                    <a-icon type="file" theme="twoTone" two-tone-color="#00cca2" :style="{fontSize:'36px'}" />
                                    <a-icon @click.stop="delFile" class="talkingSkills-file-close" type="close-circle" theme="twoTone" two-tone-color="#999" />
                                    <div style="margin-left:10px">
                                        <div class="talkingSkills-file-name">{{item.fileInfo.name || item.fileInfo.ext_name}}</div>
                                        <div v-if="item.fileInfo.size" class="talkingSkills-file-size">{{RenderSize(item.fileInfo.size)}}</div>
                                    </div>
                                </div>
                                <div>
                                    <a-upload
                                        :data="uploadParams"
                                        :action="uploadUrl"
                                        :multiple='false'
                                        :show-upload-list="false"
                                        :beforeUpload="beforeUploadVideo"
                                        @change="changeFile">
                                        <div style="margin-top:10px" v-if="item.fileInfo">
                                            <a-button> <a-icon type="upload" />重新上传</a-button>
                                        </div>
                                        <div v-else class="talkingSkills-video">
                                            <a-icon :style="{fontSize:'32px',color:'#999'}" type="plus" />
                                        </div>
                                    </a-upload>
                                </div>
                            </div>
                        </div>
                    </a-form-item>
                    <a-form-item>
                        <div style="color: #00cca2;cursor: pointer;" @click="addSopContents">
                            <a-icon type="plus" />
                            <span style="margin-left:10px">添加发送内容</span>
                        </div>
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script>
const formData = [
    {
        name: 'sop_type',
        label: 'SOP类型',
        type: 'radios',
        rules: [{ required: true, message: '请选择SOP类型!' }],
        items: {
        data: 'commissionType',
        label: 'label',
        value: 'value'
        },
    },
    {
        name: 'sop_cs',
        label: '',
        type: 'self',
        rules: []
    },

]
const plainOptions = [
  { label: '星期一', value: '1' },
  { label: '星期二', value: '2' },
  { label: '星期三', value: '3' },
  { label: '星期四', value: '4' },
  { label: '星期五', value: '5' },
  { label: '星期六', value: '6' },
  { label: '星期日', value: '0' },
]
    import moment from 'moment'
    import url from '@/utils/URL'
    import { RenderSize } from '@/utils/timeFormat'
    import createFilePath from '@/utils/tools'
    import checkWarp from '@/views/clientresource/sendMessage/CheckWarp'
    import { TreeSelect } from 'ant-design-vue'
    const SHOW_ALL = TreeSelect.SHOW_ALL
    export default {
        name:'edit',
        data() {
            return {
                name:'',
                SHOW_ALL,
                labelCol: { span: 4 },
                wrapperCol: { span: 18 },
                loading:false,
                confirmLoading: false,
                isCheckWarp: false,
                isEditModal: false,
                check_name_list:[],
                group_id:undefined,
                group_list:[],
                sop_contents:[],
                editModal:{},


                formData,
                visible: false,
                loading: false,
                modalTitle:'新建话术',
                form: this.$form.createForm(this),
                seleteItems: {
                    commissionType:[{label:'续费提醒',value:'expiration_reminder'},{label:'定时提醒',value:'schedule_reminder'}],
                },
                uploadParams: {},
                uploadUrl: url.uploadQiNiu,
                plainOptions,
                expiration_reminder_less_than:10,
                week_schedule:[{
                    week:[],
                    week_time: moment('09:30', 'HH:mm')
                }],
                radiosIndex:'expiration_reminder',
                sop_contents_index: 0,
                sop_contents:[
                    {
                        radioVal:'text',
                        content:'',
                        ext_name:'',
                        fileInfo:'',
                        videoSrc:'',
                        videoImg:'',
                        imgList:[],
                    }
                ],
                sop_id:''
                
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            checkWarp,
        },
        async created () {
            this.$ls.remove('check_name_list')
            this.$ls.remove('send_employee_ids')
            this.$ls.remove('send_employee_list')
            this.group_id = Number(this.$route.query.group_id)
            if(this.$route.query.sop_id){
                this.getDetail(this.$route.query.sop_id)
            }else{
                await this.$nextTick()
                this.form.setFieldsValue({sop_type:'expiration_reminder'})
                this.getGroup()
            }
        },
        methods: {
            RenderSize,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getDetail(id) {
                this.sop_id = id
                let res = await this.$store.dispatch('sopDetailAction', {sop_id:id})
            },
            async getGroup() {
                let res = await this.$store.dispatch('sopGroupAction', {})
                this.group_list = res.data
            },
            hideCheckWarp(val){
                if(val){
                    this.count = -1
                    this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                }
                this.isCheckWarp = false
            },
            handleCancel() {
                this.$router.go(-1)
            },
            showEditModal(item){
              this.isEditModal = true
            },
            async handleSave(){
                let params = {
                    sop_group: this.group_id,
                    sop_name: this.name,
                    sop_type: this.radiosIndex,
                    sop_works_ids:[],
                    sop_contents:this.sop_contents
                }
                if(this.radiosIndex == 'expiration_reminder'){
                    params.expiration_reminder_less_than = this.expiration_reminder_less_than
                }else{
                    params.schedule_reminder_week = this.week_schedule.week
                    params.schedule_reminder_week_time = this.week_schedule.week_time
                }
                this.check_name_list.forEach(item=>{
                    params.sop_works_ids.push(item.employee_id)
                })
                await this.$store.dispatch('sopAddAction',params)
                .then(res=>{
                    this.$message.success('操作成功！！')
                })
                .catch(err=>{

                })
            },



            async beforeUploadImg(file,fileList) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            async changeImg({file}){
                this.loading = true
                this.confirmLoading = true
                if(file.status == 'done'){
                    this.loading = false
                    this.confirmLoading = false
                    this.sop_contents[this.sop_contents_index].content = file.response.key
                    this.sop_contents[this.sop_contents_index].ext_name = file.name
                }
                if(file.status == 'removed'){
                    this.loading = false
                    this.confirmLoading = false
                    this.sop_contents[this.sop_contents_index].imgList = []
                    this.sop_contents[this.sop_contents_index].ext_name = ''
                    this.sop_contents[this.sop_contents_index].content = ''
                    this.form.setFieldsValue({sop_contents:''})
                }else{
                    this.loading = false
                    this.sop_contents[this.sop_contents_index].imgList = [file]
                }
            },
            async beforeUploadVideo(file,fileList) {
                if (!file) { return false }
                let res = await this.$store.dispatch('massTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    let obj = {
                        uid:file.uid,
                        url:this.uploadParams.key,
                    }
                    return true
                }
                return false
            },
            changeVideo({file}){
                this.loading = true
                this.confirmLoading = true
                if(file.status == 'done'){
                    this.loading = false
                    this.confirmLoading = false
                    this.sop_contents[this.sop_contents_index].videoImg = `http://v01.wedots.cn/${file.response.key}?vframe/jpg/offset/8/h/104`
                    this.sop_contents[this.sop_contents_index].videoSrc = `http://v01.wedots.cn/${file.response.key}`
                    this.sop_contents[this.sop_contents_index].content = file.response.key
                    this.sop_contents[this.sop_contents_index].ext_name = file.name
                }
            },
            changeFile({file}){
                this.loading = true
                this.confirmLoading = true
                if(file.status == 'done'){
                    this.loading = false
                    this.confirmLoading = false
                    this.sop_contents[this.sop_contents_index].fileInfo = file
                    this.sop_contents[this.sop_contents_index].content = file.response.key
                    this.sop_contents[this.sop_contents_index].ext_name = file.name
                }
            },
            toVideo(){
                window.open(this.sop_contents[this.sop_contents_index].videoSrc)
            },
            delVideo(){
                this.sop_contents[this.sop_contents_index].videoSrc = ''
                this.sop_contents[this.sop_contents_index].videoImg = ''
                this.uploadParams = {}
                this.form.setFieldsValue({sop_contents:''})
            },
            delFile(){
                this.sop_contents[this.sop_contents_index].fileInfo = ''
                this.uploadParams = {}
                this.form.setFieldsValue({sop_contents:''})
            },
            handleChange(e,index){
                this.sop_contents_index = index
                this.sop_contents[index].radioVal = e.target.value
                this.sop_contents[index].content = ''
                this.sop_contents[index].ext_name = ''
                this.sop_contents[index].fileInfo = ''
                this.sop_contents[index].videoImg = ''
                this.sop_contents[index].videoSrc = ''
                this.sop_contents[index].imgList = []
                this.form.setFieldsValue({sop_contents:''})
            },
            handleText(e,index){
                this.sop_contents_index = index
            },
            handleRadios(e){
                this.radiosIndex = e.target.value
            },
            addSopContents(){
                this.sop_contents.push({
                    radioVal:'text',
                    content:'',
                    ext_name:'',
                    fileInfo:'',
                    videoSrc:'',
                    videoImg:'',
                    imgList:[],
                })
            },
            delSopContents(index){
                this.sop_contents.splice(index,1)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .sendmessage{
        &-textarea{
            background: #fbfbfb;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            &-name{
                border-bottom: 1px dashed #e8e8e8;
                padding-left: 10px;
            }
            textarea{
                resize: none;
                border: none;
                background: #fbfbfb;
            }
            textarea:focus{
                box-shadow: none;
            }
        }
        &-radio{
            padding-left:10px
        }
        &-enclosure{
            background: #fbfbfb;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 0 15px;
            &-list{
                position: relative;
                display: flex;
                align-items: center;
            }
            &-edit{
                position: absolute;
                right: 0;
            }
        }
        &-count{
            padding-right: 15px;
            margin-top: 15px;
            cursor: pointer;
            display: inline-block;
        }
        &-remind{
            background: #f6ffed;
            border-radius: 3px;
            border: 1px solid #b7eb8f;
            padding-left: 15px;
        }
        &-phone{
            display: inline-block;
            position: relative;
            &-content{
                width: 224px;
                height: 350px;
                position: absolute;
                top: 90px;
                left: 20px;
                overflow-y: scroll;
                padding: 20px 5px 0 5px;
            }
            &-item{
                display: flex;
                margin-bottom: 10px;
                &-text{
                    width: 160px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                    word-break: break-all;
                    white-space: pre-wrap;
                }
                &-img{
                    max-width: 160px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                    img{
                        max-width: 144px;
                    }
                }
                &-link{
                    width: 160px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                }
                &-linkWarp{
                    display: flex;
                }
                &-linkTitle{
                    width: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
                &-linkDesc{
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-flex: 1;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 35px;
                    font-size: 12px;
                    color: rgba(0,0,0,.64);
                }
                &-linkImg{
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
    .sop-contents{
        border: 1px solid #eee;
        border-radius: 2px;
        width: 60%;
        padding: 15px;
    }
    .talkingSkills-videoBox{
        background: #fbfbfb;
        border-radius: 2px;
        border: 1px solid #eee;
        padding: 12px 16px;
    }
    .talkingSkills-videoImg{
        position: relative;
        display: inline-block;
        &-play{
            position: absolute;
            left: 50%;
            margin-left: -12px;
            top:40px;
            font-size: 24px;
        }
        &-close{
            position: absolute;
            right: -8px;
            top: -8px;
            font-size: 20px;
        }
    }
    .talkingSkills-video{
        display: table;
        float: left;
        width: 104px;
        height: 104px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        -webkit-transition: border-color 0.3s ease;
        transition: border-color 0.3s ease;
        line-height: 104px;
    }
    .talkingSkills-video:hover{
        border-color: #00cca2;
    }
    .talkingSkills-file{
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #eee;
        border-radius: 2px;
        padding: 10px;
        width: 250px;
        position: relative;
        &-name{
            width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: nowrap;
        }
        &-size{
            font-size: 12px;
            color: #909090;
        }
        &-close{
            position: absolute;
            right: -8px;
            top: -8px;
            font-size: 20px;
        }
    }
</style>